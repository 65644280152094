.fade-animation {
          opacity: 1;
          transition: opacity 1s ease-out;
}

.fade-out-animation {
          opacity: 0 !important;
}

.welcome-container {
          background-size: cover;
          background-position: center;
          height: 100vh;
          animation: zoomInOut 15s infinite;
}

@keyframes zoomInOut {
          0% {
                    transform: scale(1);
          }

          50% {
                    transform: scale(1.1);
          }

          100% {
                    transform: scale(1);
          }
}