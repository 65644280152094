/* Gallery.css */
.gallery-container {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Kolom otomatis berdasarkan lebar minimum */
          gap: 10px; /* Jarak antar gambar */
        }
        
        .gallery-item {
          position: relative;
          overflow: hidden;
        }
        
        .gallery-item img {
          width: 100%;
          height: auto;
          display: block;
        }
        
        .gallery-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5); /* Overlay warna hitam transparan */
          opacity: 0;
          transition: opacity 0.3s;
        }
        
        .gallery-item:hover .gallery-overlay {
          opacity: 1;
        }
        