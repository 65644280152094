.box {
    border: 1px solid rgba(184, 183, 165, 1);
    border-radius: 15px;
    padding: 20px;
    position: relative;
    margin-top: 20px;
    text-align: center;
}

.ribbon {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    padding: 5px 10px;
    border-radius: 15px;
}

.carousel-indicators{
    margin-bottom: -1.5rem !important;
}

.scrollable-container {
    max-height: 800px; /* Atur tinggi maksimal container */
    overflow-y: auto; /* Aktifkan scroll vertikal */
    scrollbar-width: thin; /* Untuk Firefox */
    scroll-behavior: smooth;
    scrollbar-color: #888 #f1f1f1; /* Warna scrollbar dan track untuk Firefox */
  }
  
  /* Untuk Webkit browsers (Chrome, Safari, Edge) */
  .scrollable-container::-webkit-scrollbar {
    width: 8px; /* Lebar scrollbar */
  }
  
  .scrollable-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Warna track scrollbar */
  }
  
  .scrollable-container::-webkit-scrollbar-thumb {
    background-color: #888; /* Warna thumb scrollbar */
    border-radius: 10px; /* Membuat ujung thumb bulat */
    border: 2px solid #f1f1f1; /* Border untuk thumb */
  }
  
  .scrollable-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Warna thumb saat di-hover */
  }