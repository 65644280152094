@import "~react-image-gallery/styles/css/image-gallery.css";

html {
          scroll-behavior: smooth;
}

body {
          scroll-snap-type: y mandatory;
}

.snap-scroll {
          scroll-snap-align: start;
          /* height: 90vh; */
          overflow-x: hidden;
}


.font-esthetic {
          font-family: 'Alex Brush', cursive !important;
          color: #ffffff !important;
}

.font-esthetic-tegak {
          font-family: 'Lato', cursive !important;
          color: #ffffff !important;
}

.font-arabic {
          font-family: 'Noto Naskh Arabic', serif !important;
          color: #ffffff !important;
}

.loading-page {
          position: fixed;
          inset: 0 !important;
          width: 100%;
          height: 100%;
          background-color: #111111;
          z-index: 1056 !important;
}

.img-crop {
          width: 14rem;
          height: 14rem;
          position: relative;
          overflow: hidden;
          border-radius: 50%;
}

.img-crop>img {
          display: inline;
          margin: 0 auto;
          height: 100%;
          width: auto;
}

.img-crop-square {
          width: 14rem;
          height: 14rem;
          position: relative;
          overflow: hidden;
}

.img-crop-square>img {
          display: inline;
          margin: 0 auto;
          height: 100%;
          width: auto;
}

.fas.fa-compact-disc {
          transition: transform 0.3s ease;
}

.fas.fa-compact-disc.playing {
          animation: spin 2s linear infinite;
}

@keyframes spin {
          from {
                    transform: rotate(0deg);
          }

          to {
                    transform: rotate(360deg);
          }
}

.audio-play {
          position: fixed;
          bottom: 20px;
          right: 20px;
}

.full-size-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          /* Optional: Ensures the image covers the entire area */
}


@media (min-width: 768px) {

          /* Desktop */
          #home,
          #mempelai {
                    background-size: cover;
          }
}

@media (max-width: 767px) {

          /* Mobile */
          #home,
          #mempelai {
                    background-size: 150%;
          }
}

.font-color-brown {
          color: #4f3627 !important;
}


.Toastify__toast-theme--light {
          background-color: rgba(184, 183, 165, 1) !important;
          color: white !important;
}