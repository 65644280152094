.scroll-down-animation {
          text-align: center;
          animation: bounce 1s infinite;
}

.scroll-down-animation span {
          display: block;
          margin-bottom: 10px;
          font-size: 1.2rem;
          color: white;
}

.scroll-down-animation .fas {
          font-size: 2rem;
          color: white;
          animation: bounce-arrow 1s infinite;
}

@keyframes bounce {

          0%,
          20%,
          50%,
          80%,
          100% {
                    transform: translateY(0);
          }

          40% {
                    transform: translateY(-10px);
          }

          60% {
                    transform: translateY(-5px);
          }
}

@keyframes bounce-arrow {

          0%,
          20%,
          50%,
          80%,
          100% {
                    transform: translateY(0);
          }

          40% {
                    transform: translateY(-10px);
          }

          60% {
                    transform: translateY(-5px);
          }
}